export const physicianTypes = [
  { name: "Alergologia", code: "alergolog" },
  { name: "Anestezjologia", code: "anestezjolog" },
  { name: "Chirurgia", code: "chirurg" },
  { name: "Dermatologia", code: "dermatolog" },
  { name: "Gastroenterologia", code: "gastroenterolog" },
  { name: "Geriatraia", code: "geriatr" },
  { name: "Ginekologia", code: "ginekolog" },
  { name: "Immunologia", code: "immunolog" },
  { name: "Kardiologia", code: "kardiolog" },
  { name: "Medycyna paliatywna", code: "paliat" },
  { name: "Medycyna sądowa", code: "sąd" },
  { name: "Medycyna sportowa", code: "sport" },
  { name: "Nefrologia", code: "nefrolog" },
  { name: "Neurologia", code: "neurolog" },
  { name: "Okulistyka", code: "okulist" },
  { name: "Onkologia", code: "onkolog" },
  { name: "Ortopedia", code: "ortoped" },
  { name: "Otolaryngologia", code: "otolaryngolog" },
  { name: "Patologia", code: "patolog" },
  { name: "Pediatria", code: "pediatr" },
  { name: "Położnictwo", code: "położ" },
  { name: "Psychiatria", code: "psychiatryczny" },
  { name: "Pulmonologia", code: "pulmonolog" },
  { name: "Radioterapia", code: "radiot" },
  { name: "Rehabilitacja", code: "rehabilitacji" },
  { name: "Reumatologia", code: "reumatolog" },
  { name: "Seksuologia", code: "seksuolog" },
  { name: "Toksykologia", code: "toksykolog" },
  { name: "Urologia", code: "poradnia urologiczna" },
  { name: "Wenerologia", code: "wnereolog" },
]

export const voievodshipTypes = [
  { name: "Dolnośląskie", code: "01" },
  { name: "Kujawsko-pomorskie", code: "02" },
  { name: "Lubelskie", code: "03" },
  { name: "Lubuskie", code: "04" },
  { name: "Łódzkie", code: "05" },
  { name: "Małopolskie", code: "06" },
  { name: "Mazowieckie", code: "07" },
  { name: "Opolskie", code: "08" },
  { name: "Podkarpackie", code: "09" },
  { name: "Podlaskie", code: "10" },
  { name: "Pomorskie", code: "11" },
  { name: "Śląskie", code: "12" },
  { name: "Świętokrzyskie", code: "13" },
  { name: "Warmińsko-mazurskie", code: "14" },
  { name: "Wielkopolskie", code: "15" },
  { name: "Zachodniopomorskie", code: "16" },
]
